<template>
  <ion-page>
    <ion-header
      mode="ios"
      v-if="!hideHeader"
      class="baseLayout-Header"
      :style="{
        'background-color':
          orangeHeader ? '#FF8135' :
          greyHeader ? '#A9A9A9' :
          lightgreyHeader ? '#EDEDED' : 
          transparentBackground ? 'transparent' : ''
      }"
    >
      <ion-toolbar 
        mode="ios"
        :class="[
          orangeHeader ? 'orange-toolbar' : '',
          greyHeader ? 'grey-toolbar' : '',
          lightgreyHeader ? 'lightgrey-toolbar' : '',
          transparentBackground ? 'transparent-toolbar' : ''
        ]">
        <ion-buttons slot="start" style="margin-left: 10px;">
          <div v-if="pageDefaultBackLink">
            <ion-back-button
              :class="[backButtonClass(), backButtonTextColorClass()]"
              :text="backButtonText"
              icon="placeholder"
              v-if="!ignoreHistory"
              :default-href="pageDefaultBackLink"
            ></ion-back-button>
            <ion-back-button
              :class="[backButtonClass(), backButtonTextColorClass()]"
              v-else
              :text="backButtonText"
              icon="placeholder"
              @click.prevent="back()"
              default-href=""
            ></ion-back-button>
          </div>
          <ion-menu-button
            color="primary"
            v-show="showMenuButton"
          ></ion-menu-button>
        <ion-text class="ml-4 font-weight-bold">{{ pageTitle }}</ion-text>
        </ion-buttons>
        <ion-buttons v-if="showFilter" class="ml-auto" slot="end">
          <ion-button @click="searchCondition()">
            <!-- <ion-icon slot="icon-only" :ios="Icon.ellipsisHorizontal" :md="Icon.ellipsisVertical"></ion-icon> -->
            <ion-icon slot="icon-only" :icon="Icon.searchOutline"></ion-icon>
          </ion-button>
          <ion-button @click="addButton()">
            <ion-icon slot="icon-only" :icon="Icon.addCircleOutline"></ion-icon>
          </ion-button>
        </ion-buttons>
        <ion-buttons v-if="showSearchButton" class="ml-auto" slot="end">
          <ion-button @click="searchCondition()">
            <ion-icon slot="icon-only" :icon="Icon.searchOutline"></ion-icon>
          </ion-button>
        </ion-buttons>
        <ion-buttons v-if="showAddButton" class="ml-auto" slot="end">
          <ion-button @click="addButton()">
            <div class="add_button"></div>
          </ion-button>
        </ion-buttons>
        <ion-buttons v-if="showEditButton" class="ml-auto" slot="end">
          <ion-button @click="editButton">
            <div class="edit_button"></div>
          </ion-button>
        </ion-buttons>
        <ion-buttons v-if="showLanguage" class="ml-auto" slot="end">
          <ion-button @click="changeLanguage">
            <div class="switch_lang"></div>
          </ion-button>
        </ion-buttons>
        <ion-buttons v-if="showCustom" class="ml-auto" slot="end">
          <slot name="actions-end">
          </slot>
        </ion-buttons>
        <ion-buttons v-if="showAddStaff" class="ml-auto" slot="end">
          <ion-button @click="addStaff">
            <div class="add_staff"></div>
          </ion-button>
        </ion-buttons>
        <ion-buttons v-if="showEditStaff" class="ml-auto" slot="end">
          <ion-button @click="editStaff">
            <div class="edit_staff"></div>
          </ion-button>
        </ion-buttons>
        <ion-buttons v-if="showAddRole" class="ml-auto" slot="end">
          <ion-button @click="addRole">
            <div class="add_role"></div>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content name="content" :class="(contentPadding ? 'ion-padding ' : ' ') + `${className}`" :scroll-events="true" :forceOverscroll="false">
      <slot name="content" />
    </ion-content>
    <ion-footer v-if="showFooter" style="height: 50px;" class="ion-no-border">
      <slot name="footer"></slot>
    </ion-footer>
  </ion-page>
</template>

<script>
import {
  ellipsisHorizontal,
  ellipsisVertical,
  searchOutline,
  chevronBackOutline,
  addCircleOutline,
  languageOutline,
  receiptOutline,
} from 'ionicons/icons';

import {
  IonText,
  IonPage,
  IonHeader,
  IonToolbar,
  IonContent,
  IonFooter,
  IonBackButton,
  IonButtons,
  IonMenuButton,
  IonButton,
  IonIcon,
} from '@ionic/vue';
import { useRouter } from 'vue-router';
import { Preferences as Storage } from '@capacitor/preferences';
import login from '../../composition/login';

export default {
  name: 'BaseLayout',
  props: {
    pageTitle: {
      type: String,
      required: false,
    },
    pageDefaultBackLink: {
      type: String,
      required: false,
      default: "",
    },
    showMenuButton: {
      type: Boolean,
      default: true,
    },
    ignoreHistory: {
      type: Boolean,
      default: false,
    },
    showFilter: {
      type: Boolean,
      default: false,
    },
    className: {
      type: String,
      required: false,
    },
    pointsHistoryLink: {
      type: String,
      required: false,
    },
    showLanguage: {
      type: Boolean,
      default: false,
    },
    showCustom: {
      type: Boolean,
      default: false,
    },
    showFooter: {
      type: Boolean,
      default: false,
    },
    backButtonText: {
      type: String,
      required: false,
      default: ""
    },
    orangeHeader: {
      type: Boolean,
      default: false
    },
    greyHeader: {
      type: Boolean,
      default: false
    },
    lightgreyHeader: {
      type: Boolean,
      default: false
    },
    transparentBackground: {
      type: Boolean,
      default: false
    },
    whiteBackButton: {
      type: Boolean,
      default: false
    },
    contentPadding: {
      type: Boolean,
      default: true
    },
    hideHeader: {
      type: Boolean,
      default: false,
    },
    showAddStaff: {
      type: Boolean,
      default: false,
    },
    showEditStaff: {
      type: Boolean,
      default: false,
    },
    showAddRole: {
      type: Boolean,
      default: false,
    },
    showSearchButton: {
      type: Boolean,
      default: false,
    },
    showAddButton: {
      type: Boolean,
      default: false,
    },
    showEditButton: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    IonText,
    IonPage,
    IonHeader,
    IonToolbar,
    IonContent,
    IonFooter,
    IonBackButton,
    IonButtons,
    IonMenuButton,
    IonButton,
    IonIcon,
  },
  setup() {
    const router = useRouter();
    const { setLang } = login();
    const Icon = {
      ellipsisHorizontal,
      ellipsisVertical,
      searchOutline,
      chevronBackOutline,
      addCircleOutline,
      languageOutline,
      receiptOutline,
    };

    return {
      router,
      Icon,
      setLang,
    };
  },
  mounted() {
    // console.log("showFilter: ", this.showFilter);
  },
  methods: {
    back() {
            this.router.push(this.pageDefaultBackLink);
    },
    searchCondition() {
            this.emitter.emit('topSearch');
    },
    addButton() {
            this.emitter.emit('topAdd');
    },
    editButton() {
            this.emitter.emit('topEdit');
    },
    changeLanguage() {
      if (this.$i18n.locale != 'zh') {
        this.$i18n.locale = 'zh';
      } else {
        this.$i18n.locale = 'en';
      }

      Storage.set({
        key: 'user_language',
        value: this.$i18n.locale,
      });

      this.setLang(this.$i18n.locale);
      
      this.emitter.emit('changeLanguage');
    },
    backButtonTextColorClass() {
      return this.whiteBackButton ? 'back-button-text-white' : 'back-button-text-default';
    },
    backButtonClass() {
      return this.whiteBackButton ? 'white-back-button' : 'back-button';
    },
    addStaff() {
      this.router.push({ name: 'admin-staff-add' });
    },
    editStaff() {
      this.router.push({ name: 'admin-staff-edit' });
    },
    addRole() {
      this.router.push({ name: 'admin-role-add' });
    },
  },
};
</script>
<style scoped>
.header-ios ion-toolbar:last-of-type {
  --border-width: 0 0 0;
}
.switch_lang {
  width: 32px;
  height: 32px;
  background-image: url('/assets/image/switch_lang.png');
}
.add_staff {
  width: 32px;
  height: 32px;
  background-image: url('/assets/image/addStaff.png');
}
.edit_staff {
  width: 32px;
  height: 32px;
  background-image: url('/assets/image/edit.png');
}
.add_role {
  width: 32px;
  height: 32px;
  background-image: url('/assets/image/add2.png');
}
.add_button {
  width: 32px;
  height: 32px;
  background-image: url('/assets/image/add2.png');
}
.edit_button {
  width: 32px;
  height: 32px;
  background-image: url('/assets/image/pencil2.png');
}
.back-button {
  background-image:  url('/assets/image/back.png');
  background-size: fit;
  background-repeat: no-repeat;
  background-position: left center;
  font-family: 'PingFang SC';
  font-size: 16px;
  color: #333;
}
.white-back-button {
  background-image:  url('/assets/image/back_white.png');
  background-size: fit;
  background-repeat: no-repeat;
  background-position: left center;
  font-family: 'PingFang SC';
  font-size: 16px;
  color: #333;
}
ion-back-button::part(text) {
  font-weight: 400;
  margin-left: 20px;
  padding-bottom: 3px;
}
.back-button-text-white::part(text) {
  color: #ffffff;
}
.back-button-text-default::part(text) {
  color: #333333;
}
.orange-toolbar {
  --background: #FF8135;
}
.grey-toolbar {
  --background: #A9A9A9;
}
.lightgrey-toolbar {
  --background: #EDEDED;
}
.transparent-toolbar {
  --background: transparent;
}
</style>