import { createApp } from 'vue';
import { IonicVue } from '@ionic/vue';
import { Preferences as Storage } from '@capacitor/preferences';
import mitt from 'mitt';
import { defineCustomElements } from '@ionic/pwa-elements/loader';

import App from './App.vue';
import router from './router';
import store from './store';
import Utils from './utils/index';
import i18n from './lang/i18n';

import redirectToHome from './composition/redirectToHome';

import BaseLayout from './components/base/BaseLayout.vue';
import BaseLayout2 from './components/base/BaseLayout2.vue';
import ErrorMessage from './components/ErrorMessage.vue';
import Loading from './components/Loading.vue';
import JsonExcel from "vue-json-excel3";

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/index.css';

/* Bootstrap utilities */
import './assets/css/bootstrap-grid.min.css';

// if do check user router beforeEnter. We don't have to do this here
// router.beforeEach(async (to, from, next) => {
//   const user = await Storage.get({ key: 'user' });
//   let lUserId = 0;
//   let lMemberType = 0;

//   if (user.value) {
//     const { userId, memberType } = JSON.parse(user.value);
//     lUserId = userId;
//     lMemberType = memberType;
//   }

//   if (['login', 'register'].includes(to.name) && user.value) {
//     let nameUrl = redirectToHome().routes[lMemberType];
//     if (nameUrl != undefined) {
//       next({ name: redirectToHome().routes[lMemberType] });
//       return;
//     }
//   }

//   // if (['login', 'home', 'register'].includes(to.name) && user.value) {
//   //   let nameUrl = redirectToHome().routes[lMemberType];
//   //   if (nameUrl != undefined) {
//   //     next({ name: redirectToHome().routes[lMemberType] });
//   //     return;
//   //   }
//   // }

//   if (!to.meta.lMemberType) {
//     next();
//     return;
//   }

//   if (!user.value) {
//     next({ name: 'logout' });
//     return;
//   }

//   if (!lUserId || !lMemberType) {
//     next({ name: 'logout' });
//     return;
//   }

//   to.matched.some((route) => {
//     if (typeof route.meta.memberType === 'object') {
//       if (!route.meta.memberType.some((type) => type === lMemberType)) {
//         next({ name: 'not-authorized' });
//         return;
//       }
//     }

//     if (!route.meta.memberType === lMemberType) {
//       next({ name: 'not-authorized' });
//       return;
//     }

//     next();
//   });
// });


const app = createApp(App)
  .use(IonicVue)
  .use(router)
  .use(store)
  .use(i18n);

// 全局 provide
app.provide('$t', i18n.global.t);

app.config.globalProperties.emitter = mitt();
app.config.globalProperties.$validate = Utils.validations;

app.component('base-layout', BaseLayout);
app.component('base-layout-2', BaseLayout2);
app.component('error-message', ErrorMessage);
app.component('loading', Loading);
app.component("downloadExcel", JsonExcel);

router.isReady()
  .then(() => {
    app.mount('#app');
  })
  .then(() => defineCustomElements(window));
