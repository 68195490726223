import {
  personAdd,
  power,
  enter,
  call,
  home,
  alertCircle,
  nuclear,
  ticket,
  albums,
  scan,
  man,
  businessOutline,
} from 'ionicons/icons';

import UserTypes from '../../../enums/UserTypes';

export default {
  /**
   * These items will be shown all the time, whether the user is logged in
   * or not
  */
  publicItems: [
    {
      title: 'Coupon List',
      url: '/coupon-list',
      icon: ticket,
    },
    {
      title: 'Scan QR Code',
      url: '/scan',
      icon: scan,
    },
    // {
    //   title: 'Contact',
    //   url: '/contact',
    //   icon: call,
    // },
    // {
    //   title: 'Not found',
    //   url: '/any-router-that-dont-exists',
    //   icon: alertCircle,
    // },
    // {
    //   title: 'Not authorized',
    //   url: '/not-authorized',
    //   icon: nuclear,
    // },
    {
      title: 'Division',
      type: 2,
    },
  ],
  /**
   * These items will only be shown when the user is not logged in
   */
  withoutAuth: [
    {
      title: 'Home',
      url: '/store-home',
      icon: home,
    },
    {
      title: 'Division',
      type: 2,
    },
    {
      title: 'Login',
      url: '/login',
      icon: enter,
    },
    /** Logout function is only in withoutAuth for demonstrations purposes */
    {
      title: 'Register',
      url: '/register',
      icon: personAdd,
    },
    {
      title: 'Register Phone',
      url: '/phone',
      icon: personAdd,
    },
    {
      title: 'Division',
      type: 2,
    },
  ],
  /**
   * These items will only be shown when the user is logged in
   */
  needAuth: [
    {
      title: 'Profile',
      url: '/profile',
      icon: man,
    },
    {
      title: 'Logout',
      url: '/logout',
      icon: power,
    },
    {
      title: 'Merchants',
      url: '/admin/merchants',
      icon: businessOutline,
    },
  ],
  /**
   * These items will be shown according to the type of user
   */
  [UserTypes.MEMBER]: [
    {
      title: 'Vip Card List',
      url: '/vip-card-list',
      icon: albums,
    },
    {
      title: 'Division',
      type: 2,
    },
  ],
  [UserTypes.MERCHANT]: [
    {
      title: 'Redeem Coupons',
      url: '/admin/redeem-coupons',
      icon: ticket,
    },
    {
      title: 'Use VipCards',
      url: '/admin/use-vip-cards',
      icon: albums,
    },
    {
      title: 'Admin Coupons',
      url: '/admin/coupons',
      icon: ticket,
    },
    {
      title: 'Admin VipCards',
      url: '/admin/vip-cards',
      icon: albums,
    },
    {
      title: 'Admin SMS',
      url: '/admin/smstemplatelist',
      icon: albums,
    },
    {
      title: 'Division',
      type: 2,
    },
  ],
  [UserTypes.ADMIN]: [
    {
      title: 'Redeem Coupons',
      url: '/admin/redeem-coupons',
      icon: ticket,
    },
    {
      title: 'Use VipCards',
      url: '/admin/useVip-cards',
      icon: albums,
    },
    {
      title: 'Admin Members',
      url: '/admin/members',
      icon: home,
    },
    {
      title: 'Admin Merchants',
      url: '/admin/merchants',
      icon: home,
    },
    {
      title: 'Admin Coupons',
      url: '/admin/coupons',
      icon: ticket,
    },
    {
      title: 'Admin VipCards',
      url: '/admin/vip-cards',
      icon: albums,
    },
    {
      title: 'Admin Advertising',
      url: '/admin/advertisings',
      icon: albums,
    },
    {
      title: 'Admin Group',
      url: '/admin/groups',
      icon: albums,
    },
    {
      title: 'Admin Mailchimp',
      url: '/admin/mailchimp',
      icon: albums,
    },
    {
      title: 'Admin SMS',
      url: '/admin/sms',
      icon: albums,
    },
    {
      title: 'Division',
      type: 2,
    },
  ],
};
