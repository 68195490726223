export default {
  setUserId(state, userId) {
    state.userId = userId;
  },
  setMemberType(state, memberType) {
    state.memberType = memberType;
  },
  setUsername(state, username) {
    state.username = username;
  },
  setMerchantId(state, merchant_id) {
    state.merchant_id = merchant_id;
  },
  setLastMerchantId(state, lastMerchant_id) {
    state.lastMerchant_id = lastMerchant_id;
  },
};
