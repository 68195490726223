import { createRouter, createWebHistory } from '@ionic/vue-router';
import role from '../composition/role';

// import client from './authenticated/client';
import admin from './authenticated/admin';

const routes = [
  {
    path: '/',
    redirect: '/launch',
  },
  {
    path: '/launch',
    name: 'launch',
    component: () => import('../pages/Launch.vue'),
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('../pages/home/Index.vue'),
    beforeEnter: (to, from, next) => {
      const { checkPage } = role();
      checkPage('home').then((rt) => {
        if (rt) {
          next(); // Take you to /something
        } else {
          // If params.blah is blank or in your case, does not have permission, redirect back to the home page
          next({ name: 'launch' });
        } 
      }).catch ((err) => {  // to.params.blah
        next({ name: 'launch' }); 
      });
    }
  },
  {
    path: '/avatar',
    name: 'avatar',
    component: () => import('../pages/Avatar.vue'),
  },
  ...admin,
  {
    path: '/login/:mid',
    name: 'login-merchant',
    component: () => import('../pages/Login.vue'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../pages/Login.vue'),
  },
  {
    path: '/resetpassword',
    name: 'resetpassword',
    component: () => import('../pages/ResetPassword.vue'),
  },
  {
    path: '/modifypassword',
    name: 'modifypassword',
    component: () => import('../pages/ModifyPassword.vue'),
  },
  {
    path: '/recovery-password',
    name: 'recovery-password',
    component: () => import('../pages/RecoverPassword.vue'),
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('../pages/Register.vue'),
  },
  {
    path: '/register/:mid',
    name: 'register merchant',
    component: () => import('../pages/Register.vue'),
  },
  {
    path: '/phone/:mid',
    name: 'phone-merchant',
    component: () => import('../pages/Phone.vue'),
  },
  {
    path: '/phone',
    name: 'phone',
    component: () => import('../pages/Phone.vue'),
  },
  {
    path: '/setup-password',
    name: 'setup-password',
    component: () => import('../pages/SetupPassword.vue'),
  },
  {
    path: '/smsverify',
    name: 'sms-verify',
    component: () => import('../pages/SmsVerify.vue'),
  },
  {
    path: '/smsverify/resetpw',
    name: 'sms-verify-resetpw',
    component: () => import('../pages/SmsVerify_resetpw.vue'),
  },
  {
    path: '/personal-info',
    name: 'personal-info',
    component: () => import('../pages/PersonalInfo.vue'),
    beforeEnter: (to, from, next) => {
      const { checkPage } = role();
      checkPage('personal-info').then((rt) => {
        if (rt) {
          next(); // Take you to /something
        } else {
          // If params.blah is blank or in your case, does not have permission, redirect back to the home page
          next({ name: 'launch' });
        } 
      }).catch ((err) => {  // to.params.blah
        next({ name: 'launch' }); 
      });
    }
  },
  {
    path: '/merchant-panel',
    name: 'merchant-panel',
    component: () => import('../pages/MerchantPanel.vue'),
    beforeEnter: (to, from, next) => {
      const { checkPage } = role();
      checkPage('merchant-panel').then((rt) => {
        if (rt) {
          next(); // Take you to /something
        } else {
          // If params.blah is blank or in your case, does not have permission, redirect back to the home page
          next({ name: 'launch' });
        } 
      }).catch ((err) => {  // to.params.blah
        next({ name: 'launch' }); 
      });
    }
  },
  {
    path: '/scan',
    name: 'scan',
    component: () => import('../pages/Scan.vue'),
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('../pages/Profile.vue'),
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('../pages/Contact.vue'),
  },
  {
    path: '/logout',
    name: 'logout',
    component: () => import('../pages/Logout.vue'),
  },
  {
    path: '/not-authorized',
    name: 'not-authorized',
    component: () => import('../pages/NotAuthorized.vue'),
  },
  {
    path: '/:catchAll(.*)',
    name: 'not-found',
    component: () => import('../pages/Error404.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
