<template>
  <IonApp>
    <IonSplitPane content-id="main-content">
      <!-- <Menu /> -->
      <ion-router-outlet id="main-content"></ion-router-outlet>
    </IonSplitPane>
  </IonApp>
</template>

<script>
import {
  IonApp,
  IonRouterOutlet,
  IonSplitPane,
} from '@ionic/vue';

import { defineComponent } from 'vue';
import { App } from '@capacitor/app';
import { Preferences as Storage } from '@capacitor/preferences';
import { TextZoom } from '@capacitor/text-zoom';

// import Menu from './components/Menu.vue';

export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    IonRouterOutlet,
    IonSplitPane,
  },
  mounted() {
    this.getLastLocale();
    App.addListener('backButton', (e) => {
      let location = this.$router.options.history.location;
      if (location.includes('/merchant-panel') || location.includes('/login')) {
        App.exitApp();
      }
    });
    
    // set font size fixed.
    if (window.Capacitor.platform != 'web') {
      TextZoom.set({
          value: 1.0,
        });
    }
  },
  beforeUnmount() {
    App.removeAllListeners();
  },
  methods: {
    getLastLocale() {
      Storage.get({ key: 'user_language' }).then((v) => {
        if (v.value && ((v.value == 'zh') || (v.value == 'en'))) {
          this.$i18n.locale = v.value;
        }
      });
    },
  }
});
</script>
