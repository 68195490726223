import redirectToHome from './redirectToHome';
import useToast from './useToast';
import api from '../api';
import { Preferences as Storage } from '@capacitor/preferences';
import UserTypes from '../enums/UserTypes';

import md5 from 'js-md5'

export default function () {
  const { openToast } = useToast();
  const { redirectTo } = redirectToHome();

  async function userLogin(userCredentials) {
    const cur_merchant = await Storage.get({ key: 'cur_merchant_id' });
    let cur_merchant_id = cur_merchant.value?cur_merchant.value:0;
    const data = {
      username: userCredentials.username,
      password: userCredentials.password,
      lastMerchant_id: userCredentials.lastMerchant_id,
      lang: userCredentials.lang,
    };

    return this.$store.dispatch('login/login', data)
      .then((response) => {
        if (response.status == 0) {
          // openToast('Logged with sucess', 'success', 'top');
        } else {
          if (response.message == 'Unknown user') {
            openToast(this.$t('Please confirm your username or password are correct'), 'danger', 'top');  
          } else {
            openToast(response.message, 'danger', 'top');  
          }
        }

        // redirectTo(
        //   response.data.user.memberType,
        //   { anyCustomParams: respones.data.user },
        //   true,
        // );

        return Promise.resolve(response);
      })
      .catch(() => {
        openToast('Something was wrong on login', 'danger', 'top');
        Promise.reject("Login got Exception");
      });
  }

  // Setup phone number in system first time
  async function registerPhone(phone) {
    var form = new FormData();
    form.append("phone", phone);
    try {
      const response = await api.post('/phone', form);
      if (response.status == 200) {
        const data = response.data;
        return data;
      }
      openToast('Unknown Phone Number, please try later', 'warning', 'top');
      return "";
    } catch (error) {
      openToast('Unknown phone number, please try later', 'warning', 'top');
      return "";
    }
  }

  // Setup phone number in system first time
  async function verifyPhone(phone, code) {
    var form = new FormData();
    form.append("phone", phone);
    form.append("code", code);
    try {
      const response = await api.post('/verifyphone', form);
      if (response.status == 200) {
        const data = response.data;
        return data;
      }
      openToast('Unknown Phone Number, please try later', 'warning', 'top');
      return "";
    } catch (error) {
      openToast('Unknown phone number, please try later', 'warning', 'top');
      return "";
    }
  }

  async function userRegister(userFrom) {
    const cur_merchant = await Storage.get({ key: 'cur_merchant_id' });
    let cur_merchant_id = cur_merchant.value?cur_merchant.value:0;
    const member = await Storage.get({ key: 'memberType' });
    let memberType = member.value?member.value:UserTypes.MEMBER;
    var form = new FormData();
    if ((userFrom.username == undefined) || !userFrom.username) {
      openToast('Unknown User Phone number', 'warning', 'top');
      return "";
    }
    if ((userFrom.password == undefined) || !userFrom.password) {
      openToast('Unknown User Password', 'warning', 'top');
      return "";
    }
    form.append("username", userFrom.username);
    form.append("password", userFrom.password);
    form.append("memberType", memberType);
    form.append("merchant_id", cur_merchant_id);
    form.append("lastMerchant_id", cur_merchant_id);
    if (userFrom.name != undefined) {
      form.append("name", userFrom.name);
    }
    if (userFrom.address != undefined) {
      form.append("address", userFrom.address);
    }
    if (userFrom.email != undefined) {
      form.append("email", userFrom.email);
    }
    form.append("phone", userFrom.username);
    if (userFrom.notes != undefined) {
      form.append("notes", userFrom.notes);
    }
    try {
      const response = await api.post('/register', form);
      if (response.status == 200) {
        // openToast('Logged with sucess', 'success', 'top');
        const data = response.data;
        if (data.status == 0) {
          await Storage.set({
            key: 'token',
            value: data.data.token,
          });
          let user = data.data.user;
          user.userId = user.id;
          await Storage.set({
            key: 'user',
            value: JSON.stringify(user),
          });
        }
        return data;
      }
      openToast('User Register Error, please try later', 'warning', 'top');
      return "";
    } catch (error) {
      throw error;
    }
  }

  async function userCheckUsername(username) {
    var form = new FormData();
    form.append("token", md5(username + 'posW230810'));
    form.append("username", username);
    try {
      const response = await api.post('/check', form);
      if (response.status == 200) {
        const data = response.data;
        if ((data.status == 0) && (data.data.message == "")) {
          return "";
        }
      }
      openToast('Username Existed, please try other one', 'warning', 'top');
      return 'Username Existed, please try other one';
    } catch (error) {
      throw error;
    };
  }

  async function userRegister_byAdmin(userFrom) {
    let merchant_id = 0;
    let memberType = UserTypes.MEMBER;
    var form = new FormData();
    form.append("username", userFrom.username);
    form.append("password", userFrom.password);
    form.append("memberType", memberType);
    form.append("merchant_id", merchant_id);
    form.append("lastMerchant_id", merchant_id);
    form.append("name", userFrom.name);
    form.append("address", userFrom.address);
    form.append("email", userFrom.email);
    form.append("phone", userFrom.username);
    form.append("notes", userFrom.notes);
    try {
      const response = await api.post('/register', form);
      if (response.status == 200) {
        openToast('Registered successfully', 'success', 'top');
        const data = response.data;
        return data;
      }
      openToast('User Register Error, please try later', 'warning', 'top');
      return "";
    } catch (error) {
      throw error;
    }
  }

  async function sendResetPassword(phone) {
    var form = new FormData();
    form.append("phone", phone);
    try {
      const response = await api.post('/resetpwd', form);
      if (response.status == 200) {
        openToast('Please check your phone', 'success', 'top');
        const data = response.data;
        return data;
      }
      openToast('Refuse to reset password', 'warning', 'top');
      return "";
    } catch (error) {
      throw error;
    }
  }

  async function updatePassword(password) {
    const tokenv = await Storage.get({ key: 'token' });
    if (!tokenv.value) {
      // Permission error
      // openToast('Timeout. Please login again', 'warning', 'top');
      return "";
    }
    let token = tokenv.value;
    const userdata = await Storage.get({ key: 'user' });
    let user = userdata.value?JSON.parse(userdata.value):false;
    if (!user) {
      // Permission error
      openToast('Permission Denied', 'warning', 'top');
      return "";
    }
    var form = new FormData();
    form.append("token", token);
    form.append("id", user.userId);
    form.append("password", password);
    try {
      const response = await api.post('/setpwd', form);
      if (response.status == 200) {
        const data = response.data;
        return data;
      }
      openToast('Set Password Fail', 'warning', 'top');
      return "";
    } catch (error) {
      throw error;
    }
  }

  async function modifyPassword(oldPsw, newPsw) {
    const tokenv = await Storage.get({ key: 'token' });
    if (!tokenv.value) {
      // Permission error
      // openToast('Timeout. Please login again', 'warning', 'top');
      return "";
    }
    let token = tokenv.value;
    const userdata = await Storage.get({ key: 'user' });
    let user = userdata.value?JSON.parse(userdata.value):false;
    if (!user) {
      // Permission error
      openToast('Permission Denied', 'warning', 'top');
      return "";
    }
    var form = new FormData();
    form.append("token", token);
    form.append("old", oldPsw);
    form.append("new", newPsw);
    try {
      const response = await api.post('/modifyPassword', form);
      if (response.status == 200) {
        const data = response.data;
        return data;
      }
      openToast('Failed to modify password', 'warning', 'top');
      return "";
    } catch (error) {
      throw error;
    }
  }
  
  async function getPersonalInfo() {
    const tokenv = await Storage.get({ key: 'token' });
    if (!tokenv.value) {
      // Permission error
      // openToast('Timeout. Please login again', 'warning', 'top');
      return "login";
    }
    let token = tokenv.value;
    const userdata = await Storage.get({ key: 'user' });
    let user = userdata.value?JSON.parse(userdata.value):false;
    if (!user) {
      // Permission error
      openToast('Permission Denied', 'warning', 'top');
      return "";
    }
    var form = new FormData();
    form.append("token", token);
    const merchant = await Storage.get({ key: 'staff_merchant_id' });
    if (merchant && merchant.value) {
      form.append("merchant_id", merchant.value);
    }
    try {
      const response = await api.post('/getPersonalInfo', form);
      if (response.status == 200) {
        const data = response.data;
        return data;
      }
      openToast('Get PersonalInfo Fail', 'warning', 'top');
      return "";
    } catch (error) {
      throw error;
    }
  }

  async function updateSelfInfo(data) {
    const tokenv = await Storage.get({ key: 'token' });
    if (!tokenv.value) {
      // Permission error
      // openToast('Timeout. Please login again', 'warning', 'top');
      return "";
    }
    let token = tokenv.value;
    const userdata = await Storage.get({ key: 'user' });
    let curuser = userdata.value?JSON.parse(userdata.value):false;
    if (!curuser) {
      // Permission error
      openToast('Permission Denied', 'warning', 'top');
      return "";
    }
    var form = new FormData();
    form.append("token", token);
    form.append("data", JSON.stringify(data));

    try {
      const response = await api.post('/updateSelfInfo', form);
      if (response.status == 200) {
        const data = response.data;
        return data;
      }
      openToast('Failed to update account info', 'warning', 'top');
      return "";
    } catch (error) {
      throw error;
    }
  }
  
  async function saveAvatar(avatar, fname) {
    const tokenv = await Storage.get({ key: 'token' });
    if (!tokenv.value) {
      // Permission error
      // openToast('Timeout. Please login again', 'warning', 'top');
      return "";
    }
    let token = tokenv.value;
    const userdata = await Storage.get({ key: 'user' });
    let curuser = userdata.value?JSON.parse(userdata.value):false;
    if (!curuser) {
      // Permission error
      openToast('Permission Denied', 'warning', 'top');
      return "";
    }
    var form = new FormData();
    form.append("token", token);
    form.append("avatar", avatar, fname);

    try {
      const response = await api.post('/updateAvatar', form);
      if (response.status == 200) {
        const data = response.data;
        return data;
      }
      openToast('Saving avatar failed', 'warning', 'top');
      return "";
    } catch (error) {
      throw error;
    }
  }
  
  async function setLang(lang) {
    const tokenv = await Storage.get({ key: 'token' });
    if (!tokenv.value) {
      // Permission error
      return "";
    }
    let token = tokenv.value;
    const userdata = await Storage.get({ key: 'user' });
    let curuser = userdata.value?JSON.parse(userdata.value):false;
    if (!curuser) {
      // Permission error
      return "";
    }
    var form = new FormData();
    form.append("token", token);
    form.append("lang", lang);

    try {
      api.post('/setLang', form);
    } catch (error) {
    }
  }

  return {
    userLogin,
    registerPhone,
    verifyPhone,
    userRegister,
    userRegister_byAdmin,
    userCheckUsername,
    openToast,
    redirectTo,
    sendResetPassword,
    updatePassword,
    modifyPassword,
    getPersonalInfo,
    updateSelfInfo,
    saveAvatar,
    setLang,
  };
}
