export default function () {
  function captitalizeString(string) {
    return string[0].toUpperCase() + string.slice(1).toLowerCase();
  }

  function isWebPlatform() {
    if(window.Capacitor.platform === 'web') {
      return true;
    } 
    return false;
  };

  function getMerchantName(i18n, merchant) {
    return i18n.locale == 'en' ? merchant.storeName : merchant.storeName2;
  }
  
  function getMerchantPromoWording(i18n, merchant) {
    return i18n.locale == 'en' ? merchant.promoWording : merchant.promoWording2;
  }
  
  function getCouponName(i18n, coupon) {
    return i18n.locale == 'en' ? coupon.name : coupon.name2;
  }
  
  function getCouponPromoWording(i18n, coupon) {
    return i18n.locale == 'en' ? coupon.promoWording : coupon.promoWording2;
  }

  function getVipCardPointsRules(i18n, card) {
    return i18n.locale == 'en' ? card.pointsRules : card.pointsRules2;
  }

  function getPosProductName(i18n, p) {
    return i18n.locale == 'en' ? p.name1 : p.name2;
  }

  function getName(i18n, data) {
    return i18n.locale == 'en' ? data.name : data.name2;
  }
  
  function getParentStoreName(i18n, data) {
    return i18n.locale == 'en' ? data.parentStoreName : data.parentStoreName2;
  }

  function getProductName(i18n, data) {
    return i18n.locale == 'en' ? data.name1 : data.name2;
  }

  function getBannerName(i18n, data) {
    return i18n.locale == 'en' ? data.title_en : data.title_zh;
  }

  function getBannerDesc(i18n, data) {
    return i18n.locale == 'en' ? data.desc_en : data.desc_zh;
  }

  return {
    captitalizeString,
    isWebPlatform,
    getMerchantName,
    getMerchantPromoWording,
    getCouponName,
    getCouponPromoWording,
    getVipCardPointsRules,
    getPosProductName,
    getName,
    getParentStoreName,
    getProductName,
    getBannerName,
    getBannerDesc,
  };
}
