<template>
  <ion-page @click="clickPage">
    <ion-header mode="ios" class="MP-header">
      <ion-toolbar mode="ios">
        <ion-buttons slot="start">
          <ion-button @click.stop="clickMerchant">
            <ion-label slot="start" class="merchant_panel_header_text">
              {{ getMerchantName($i18n, merchant) }}
              <span v-if="merchantIDList.length > 1" class="arrow">&#9662;</span>
            </ion-label>
          </ion-button>
        </ion-buttons>
        <ion-avatar slot="end" class="merchant_panel_header_avatar" @click="gotoAvatar">
          <img :src="member.avatar ? member.avatar : '/assets/image/avatar5.png'" />
        </ion-avatar>
      </ion-toolbar>
    </ion-header>

    <ion-content name="content" :scroll-events="true" :forceOverscroll="false">
      <slot name="content" />
    </ion-content>

    <ion-footer class="ion-no-border height-50 white-bg">
      <div class="merchant_scan_container">
        <div class='merchant_scan' @click="merchantScan"></div>
      </div>
      
      <div class='poweredby'>
        <span class="grey-font">Powered by </span><span class="orange-font">POSKING&thinsp;</span><span class="grey-font">{{ "v" + version }}</span>
      </div>
    </ion-footer>

  </ion-page>
</template>

<script>
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonContent,
  IonFooter,
  IonButtons,
  IonButton,
  IonAvatar,
  IonLabel,

} from '@ionic/vue';
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import utils from '../../composition/utils';
import { VERSION } from '@/config';

export default {
  name: 'BaseLayout2',
  props: {
    member: {
      type: Object,
      default: () => ({})
    },
    merchant: {
      type: Object,
      default: () => ({})
    },
    merchantIDList: {
      type: Array,
      default: () => []
    }
  },
  components: {
    IonPage,
    IonHeader,
    IonToolbar,
    IonContent,
    IonFooter,
    IonButtons,
    IonButton,
    IonAvatar,
    IonLabel,
  },
  setup() {
    const router = useRouter();
    const { getMerchantName } = utils();
    const needInit = ref(true);
    const showDropdown = ref(false);

    return {
      router,
      getMerchantName,
      needInit,
      showDropdown,
      version: VERSION,
    };
  },
  updated() {
    if (window.location.pathname.includes("/merchant-panel")) {
      if (this.needInit) {
        this.needInit = false;
      }
    } else {
      this.needInit = true;
      this.showDropdown = false;
    }
  },
  watch: {
    showDropdown(newValue) {
      this.$emit('showDropdownChanged', newValue);
    },
  },
  methods: {
    gotoAvatar() {
      this.router.push({name: 'avatar'});
    },
    merchantScan() {
      this.router.push({name: 'admin-scan'});
    },
    clickMerchant() {
      if(this.merchantIDList.length > 1) {
        this.showDropdown = !this.showDropdown;
      }
    },
    clickPage() {
      if (this.showDropdown) {
        this.showDropdown = false;
      }
    },
  },
};
</script>
<style scoped>
.header-ios ion-toolbar:last-of-type {
  --border-width: 0 0 0;
}
.height-50 {
  height: 50px;
}
.grey-font {
  color: #A9A9A9;
}
.orange-font {
  color: #FF8135;
}
.white-bg {
  background-color: white;
}
.merchant_scan_container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 100%;
}
.merchant_scan {
  width: 98px;
  height: 100px;
  background-image: url('/assets/image/merchant_scan.png');
  position: absolute; /* Adjust if needed */
  top: -60%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Adjust for precise centering */
}
.poweredby {
  width: 100%;
  text-align: center;
  font-size: 0.75rem;
  padding-top: 25px;
  position: absolute; /* 绝对定位 */
  bottom: 10px;
  text-align: center;
  font-size: 0.75rem;
  padding-top: 25px;
}
.MP-header {
  height: 60px;
  background-color: #FF8135;
}
.MP-header ion-toolbar {
  --background: #FF8135; 
}
.merchant_panel_header_text {
  color: white;
  font-weight: 500;
  font-size: 16px;
  margin-top: 6px;
  margin-left: 10px;
}
.arrow {
  font-size: 20px;
  margin-right: 5px;
  color: rgba(255, 255, 255, 1);
}
.merchant_panel_header_avatar {
  width: 40px;
  height: 40px;
  margin-top: 6px;
  margin-right: 10px;
}
</style>